@font-face {
  font-family: 'EurofontC';
  src: local('EurofontC'),
  url('../assets/fonts/subset-EurofontC.woff2') format('woff2'),
  url('../assets/fonts/subset-EurofontC.woff') format('woff'),
  url('../assets/fonts/subset-EurofontC.ttf') format('truetype'),
  url('../assets/fonts/subset-EurofontC.svg#EurofontC') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EurofontC';
  src: local('EurofontC Bold'), local('EurofontC-Bold'),
  url('../assets/fonts/subset-EurofontC-Bold.woff2') format('woff2'),
  url('../assets/fonts/subset-EurofontC-Bold.woff') format('woff'),
  url('../assets/fonts/subset-EurofontC-Bold.ttf') format('truetype'),
  url('../assets/fonts/subset-EurofontC-Bold.svg#EurofontC-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}



@font-face {
  font-family: 'PT Sans';
  src: local('PT Sans'), local('PTSans-Regular'),
  url('../assets/fonts/subset-PTSans-Regular.woff2') format('woff2'),
  url('../assets/fonts/subset-PTSans-Regular.woff') format('woff'),
  url('../assets/fonts/subset-PTSans-Regular.ttf') format('truetype'),
  url('../assets/fonts/subset-PTSans-Regular.svg#PTSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PT Sans';
  src: local('PT Sans Bold'), local('PTSans-Bold'),
  url('../assets/fonts/subset-PTSans-Bold.woff2') format('woff2'),
  url('../assets/fonts/subset-PTSans-Bold.woff') format('woff'),
  url('../assets/fonts/subset-PTSans-Bold.ttf') format('truetype'),
  url('../assets/fonts/subset-PTSans-Bold.svg#PTSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}